import { CSSProperties, useEffect, useState } from 'react';

import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    createFilterOptions,
    IconButton,
    Paper,
    Snackbar,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IntegrationContextType, useIntegration } from '../contexts/IntegrationProvider';

import { Mapping, MappingValue, WebAppConfig, WebappEntryType } from '@progyconnect/webapp-types';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../redux/state';
import { Dispatch } from 'redux';
import { Action, ActionTypes } from '../redux/Action';

import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function Mappings({
    type,
    showMissingsOnly,
    setShowMissingsOnly,
}: {
    type: WebappEntryType;
    showMissingsOnly: boolean;
    setShowMissingsOnly: (showMissingsOnly: boolean) => void;
}) {
    const { t } = useTranslation();
    const mappings = useSelector<State, { [key: string]: Mapping[] } | undefined>((state) => state.mappings);
    const mappingValues = useSelector<State, { [key: string]: MappingValue[] } | undefined>(
        (state) => state.mappingValues,
    );
    const config = useSelector<State, WebAppConfig | undefined>((state) => state.config);

    const dispatch = useDispatch<Dispatch<Action>>();
    const { apiClient } = useIntegration() as IntegrationContextType;

    const [openSnackbarMessage, setOpenSnackbarMessage] = useState<string | undefined>();

    const [updatingValues, setUpdatingValues] = useState<boolean>(false);

    const valueType = type.type === 'mapping' ? type.valueClass : undefined;

    if (!mappings || !mappingValues || updatingValues || !valueType) {
        return (
            <div
                style={{
                    height: '100vh',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    const MappingValueComboBox = ({ mapping }: { mapping: Mapping }) => {
        const getSelectedValue = (mapping: Mapping) => {
            let value: MappingValue | undefined = undefined;
            if (!mapping) value = undefined;
            else if (mapping.state === 'mapped') {
                if (type.type === 'mapping' && mappingValues?.[type.valueClass!])
                    value = mappingValues[type.valueClass!].find((mv) => mv.id === mapping.externalEntityId)!;
            }

            return value;
        };

        return (
            <Box sx={{ paddingTop: '3px' }}>
                <Autocomplete
                    disablePortal
                    size='small'
                    disableClearable={true}
                    multiple={false}
                    options={mappingValues?.[valueType]?.sort((v1, v2) => v1.value?.localeCompare(v2.value)) ?? []}
                    renderInput={(params) => <TextField {...params} />}
                    getOptionLabel={(v) => (typeof v === 'string' ? v : v.value)}
                    filterOptions={createFilterOptions({
                        stringify: (option) => {
                            let str = option.value;
                            if (option.description) str += ' ' + option.description;
                            if (option.address?.Line1) str += ' ' + option.address.Line1;
                            if (option.address?.City) str += ' ' + option.address.City;

                            return str;
                        },
                    })}
                    renderOption={(props, v) => {
                        if (v.address && (v.address.Line1 || v.address.City)) {
                            return (
                                <li {...props}>
                                    <div style={{ display: 'block' }}>
                                        <div>{v.value}</div>
                                        <div
                                            style={{
                                                color: 'gray',
                                                fontSize: '10pt',
                                            }}
                                        >
                                            {v.address.Line1 ?? t('Unknown address')}{' '}
                                            {v.address.City ?? t('Unknown city')}
                                        </div>
                                    </div>
                                </li>
                            );
                        } else if (v.description) {
                            return (
                                <li {...props}>
                                    <div style={{ display: 'block' }}>
                                        <div>{v.value}</div>
                                        <div
                                            style={{
                                                color: 'gray',
                                                fontSize: '10pt',
                                            }}
                                        >
                                            {v.description}
                                        </div>
                                    </div>
                                </li>
                            );
                        } else {
                            return <li {...props}>{v.value}</li>;
                        }
                    }}
                    defaultValue={getSelectedValue(mapping)}
                    onChange={(event, value: MappingValue) => {
                        apiClient.saveMappings([
                            {
                                integrationIdAndType: mapping.integrationIdAndType,
                                entityId: mapping.entityId,
                                externalEntityId: value.id,
                            },
                        ]);
                        dispatch({
                            type: ActionTypes.SAVE_MAPPING,
                            payload: {
                                type: type.class,
                                entityId: mapping.entityId,
                                externalEntityId: value.id,
                            },
                        });
                    }}
                />
            </Box>
        );
    };

    const getSourceLabel = (params: Mapping) => {
        return params.label;
    };

    const rowRenderer = ({ index, style }: { index: number; style: CSSProperties }) => {
        const mappings = getEffectiveMappings();
        if (mappings.length <= index) return <>problems...</>;
        const mapping = mappings[index];
        return (
            <div
                key={index}
                style={{
                    ...style,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid #D3D3D3',
                    padding: '5px',
                }}
            >
                <div style={{ flex: 1 }}>{getSourceLabel(mapping!)}</div>
                {type.extraColumns?.map((c) => (
                    <Typography key={'custom' + c.id} style={{ flex: 1 }}>
                        <span key={c.id + '-' + index}>{(mapping as any)[c.id]}</span>
                    </Typography>
                ))}
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                        <MappingValueComboBox mapping={mapping!} />
                    </div>
                    <div>
                        <Tooltip title={t('Copy workflowId to help with debug')}>
                            <IconButton
                                onClick={(event) => {
                                    const workflowId = mapping.integrationIdAndType + '_' + mapping.entityId;
                                    navigator.clipboard.writeText(workflowId);
                                    setOpenSnackbarMessage('WorkflowId copied to clipboard');
                                }}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </div>
        );
    };

    function getEffectiveMappings(): Mapping[] {
        if (!mappings?.[type.class]) return [];
        if (showMissingsOnly) return mappings[type.class].filter((m) => m.state !== 'mapped');
        return mappings[type.class];
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '0 0px 10px 0px',
                    borderBottom: '1px solid #e0e0e0',
                    position: 'relative',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'baseline', gap: '14px' }}>
                    <Tooltip
                        title={t(
                            'Refresh the list of items in the list by retrieving the latest data from {{software}}.',
                            { software: config!.type },
                        )}
                    >
                        <Button
                            style={{ width: '220px' }}
                            variant='contained'
                            size='small'
                            onClick={async () => {
                                if (!valueType) return;

                                setUpdatingValues(true);

                                const newValues = await apiClient.getMappingValues(valueType, true);

                                dispatch({
                                    type: ActionTypes.UPDATE_MAPPING_VALUES,
                                    payload: {
                                        type: valueType,
                                        mappingValues: newValues,
                                    },
                                });
                                setUpdatingValues(false);
                            }}
                        >
                            {t('Update list')}
                        </Button>
                    </Tooltip>
                </div>
                <div style={{ display: 'flex', gap: '12px', width: 'stretch', paddingLeft: '20px' }}>
                    <div className='label'>{t('Show only missing mappings')}</div>
                    <input
                        checked={showMissingsOnly}
                        type='checkbox'
                        onChange={(event) => {
                            const checked = event.target.checked;
                            setShowMissingsOnly(checked);
                        }}
                    />
                </div>
            </div>
            <br />
            <div style={{ position: 'relative', height: '100vh' }}>
                <div style={{ position: 'sticky', top: 0, zIndex: 100 }}>
                    <Paper
                        elevation={2}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: '10px',
                        }}
                    >
                        <div style={{ flex: 1 }}>{t('Name')}</div>
                        {(type.extraColumns ?? []).map((c) => (
                            <div key={`head-${c.id}`} style={{ flex: 1 }}>
                                {t(c.label)}
                            </div>
                        ))}
                        <div style={{ flex: 1 }}>{config!.type === 'Qbo' ? 'Quickbooks' : 'Acomba'}</div>
                    </Paper>
                </div>
                <AutoSizer>
                    {({ height, width }) => (
                        <List
                            height={height ?? 0}
                            itemCount={getEffectiveMappings().length}
                            itemSize={55}
                            width={width ?? 0}
                        >
                            {rowRenderer}
                        </List>
                    )}
                </AutoSizer>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(openSnackbarMessage)}
                autoHideDuration={2000}
                onClose={() => setOpenSnackbarMessage(undefined)}
                message={t(openSnackbarMessage ?? '')}
            />
        </div>
    );
}
