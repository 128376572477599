import { Order } from '@progyconnect/webapp-types';

export type RefreshCallback = (completedItems: Order[]) => void;
export type GetUpdatedOrdersFunction = (orders: Order[]) => Promise<Order[]> | undefined;

export class OrderRefreshTracker {
    private trackedOrders: Order[] = [];
    private refreshIntervalId: NodeJS.Timeout | undefined;
    private refreshCallback: RefreshCallback;
    private updateOrdersStatusCallback: GetUpdatedOrdersFunction;

    constructor(callback: RefreshCallback, getUpdatedOrders: GetUpdatedOrdersFunction) {
        this.refreshCallback = callback;
        this.updateOrdersStatusCallback = getUpdatedOrders;
    }

    public clear() {
        this.trackedOrders = [];
        this.stopRefreshInterval();
    }

    public addOrder(item: Order): void {
        if (this.trackedOrders.find((order) => order.entityId === item.entityId) === undefined) {
            this.trackedOrders.push(item);
            this.startRefreshInterval();
        }
    }

    public stop() {
        this.stopRefreshInterval();
    }

    private startRefreshInterval(): void {
        if (this.refreshIntervalId) {
            return;
        }

        this.refreshIntervalId = setInterval(async () => {
            await this.refreshOrders();
        }, 10000);
    }

    private stopRefreshInterval(): void {
        if (this.refreshIntervalId) {
            clearInterval(this.refreshIntervalId);
            this.refreshIntervalId = undefined;
        }
    }

    private async refreshOrders(): Promise<void> {
        const chunkedOrders: Order[][] = chunkArray(this.trackedOrders, 100);
        const completedOrders: Order[] = [];

        for (let i = 0; i < chunkedOrders.length; i++) {
            const updatedOrders: Order[] = this.extractUpdatedOrders(
                this.trackedOrders,
                await this.updateOrdersStatusCallback(chunkedOrders[i]),
            );

            for (let j = 0; j < updatedOrders.length; j++) {
                const updatedOrder = updatedOrders[j];
                const index = this.trackedOrders.findIndex(
                    (trackedOrder) => trackedOrder.entityId === updatedOrder.entityId,
                );

                if (index !== -1) {
                    completedOrders.push(updatedOrder);
                    this.trackedOrders.splice(index, 1);
                }
            }
        }

        if (completedOrders.length > 0 && this.refreshCallback) {
            this.refreshCallback(completedOrders);
        }

        if (this.trackedOrders.length === 0) {
            this.stopRefreshInterval();
        }
    }

    private extractUpdatedOrders(trackedOrders: Order[], updatedOrders: Order[] | undefined): Order[] {
        const updatedOrdersMap: { [entityId: string]: Order } = {};

        if (!updatedOrders) return [];

        for (const updatedOrder of updatedOrders) {
            updatedOrdersMap[updatedOrder.entityId] = updatedOrder;
        }

        const result: Order[] = [];

        for (const trackedOrder of trackedOrders) {
            const updatedOrder = updatedOrdersMap[trackedOrder.entityId];

            if (
                updatedOrder &&
                updatedOrder.lastUpdateDate > trackedOrder.lastUpdateDate &&
                updatedOrder.state !== 'processing' &&
                updatedOrder.state !== 'pending-mapping'
            ) {
                result.push(updatedOrder);
            }
        }

        return result;
    }
}

function chunkArray<T>(array: T[], chunkSize: number): T[][] {
    const chunks: T[][] = [];

    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }

    return chunks;
}
