import { CSSProperties, RefObject, useCallback, useEffect, useRef, useState } from 'react';

import Button from '@mui/material/Button';
import type {
    ColumnFilter,
    DynamoKey,
    DynamoPagedResponse,
    Mapping,
    Order,
    WebAppConfig,
    WebappEntryType,
} from '@progyconnect/webapp-types';
import { confirmDialog } from '../components/ConfirmDialog';

import WarningIcon from '@mui/icons-material/Warning';
import ReplayIcon from '@mui/icons-material/Replay';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import {
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    IconButton,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IntegrationContextType, useIntegration } from '../contexts/IntegrationProvider';
import { useLocalization } from '../contexts/LocalizationContext';
import { State } from '../redux/state';

import InfiniteLoader from 'react-window-infinite-loader';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import { OrderRefreshTracker } from './OrderRefreshTracker';
import { Action, Dispatch } from 'redux';
import { ActionTypes } from '../redux/Action';

export default function Orders({ type }: { type: WebappEntryType }) {
    const { apiClient } = useIntegration() as IntegrationContextType;
    const { t } = useTranslation();
    const { lang } = useLocalization();
    const config = useSelector<State, WebAppConfig | undefined>((state) => state.config);
    const orderRefreshTrackerRef = useRef<OrderRefreshTracker>();
    const dispatch = useDispatch<Dispatch<Action>>();
    const [mode, setMode] = useState<WebAppConfig['orderWorkflowConfigs']['transmitMode'] | undefined>(
        config?.orderWorkflowConfigs?.transmitMode,
    );

    const orders = useRef<(Order | null)[]>([]);

    const [refreshTrigger, setRefreshTrigger] = useState<number>(0);

    useEffect(() => {
        if (!apiClient) return;

        const refreshOrders = (completedOrders: Order[]) => {
            fetchMissingMappingsForPendingOrders(completedOrders);
            completedOrders.forEach((order) => {
                const index = orders.current.findIndex((o) => o !== null && o.entityId === order.entityId);
                if (index >= 0) {
                    orders.current[index] = order;
                }
            });

            setRefreshTrigger((prev) => prev + 1);
        };

        const getUpdatedOrders = (ordersToUpdate: Order[]) => {
            return apiClient.getOrdersByIds(ordersToUpdate.map((o) => o.entityId));
        };

        const orderRefreshTracker = new OrderRefreshTracker(refreshOrders, getUpdatedOrders);

        orderRefreshTrackerRef.current = orderRefreshTracker;
        init();

        return () => {
            orderRefreshTracker.stop();
        };
    }, [apiClient, lang]);

    const lastSortKey = useRef<DynamoKey | undefined>();

    const grouping: RefObject<HTMLInputElement> = useRef(null);
    const showMissingsOnly: RefObject<HTMLInputElement> = useRef(null);
    const filterByOrderNumber = useRef<string>('');

    useEffect(() => {
        const handleCheckboxChange = () => {
            init();
        };

        grouping.current?.addEventListener('change', handleCheckboxChange);
        showMissingsOnly.current?.addEventListener('change', handleCheckboxChange);

        return () => {
            grouping.current?.removeEventListener('change', handleCheckboxChange);
            showMissingsOnly.current?.removeEventListener('change', handleCheckboxChange);
        };
    }, []);

    const [openSnackbarMessage, setOpenSnackbarMessage] = useState<string | undefined>();

    //------------------------------------------------------

    const getColumnFilters = (filterByOrderNumber: string, showMissingsOnly: boolean): ColumnFilter[] => {
        if (filterByOrderNumber !== undefined && filterByOrderNumber != '')
            return [{ id: 'orderNumber', value: filterByOrderNumber }];

        if (showMissingsOnly) return [{ id: 'state', value: 'pending' }];

        return [];
    };

    const [rowSelection, setRowSelection] = useState<{ [key: string]: Order }>({});

    const init = () => {
        //clear orders when filtering conditions change
        orders.current = [];
        lastSortKey.current = undefined;
        if (orderRefreshTrackerRef.current) orderRefreshTrackerRef.current.clear();
        fetchMoreOrders(true);
    };

    const [isNextPageLoading, setIsNextPageLoading] = useState(false);

    //fetch more orders when scrolling to the bottom of the list
    const fetchMoreOrders = async (firstRun: boolean) => {
        if (!apiClient || !orderRefreshTrackerRef.current) return;

        setIsNextPageLoading(true);

        const response = await apiClient.getOrders(
            100,
            lastSortKey.current,
            getColumnFilters(filterByOrderNumber.current, showMissingsOnly.current?.checked ?? false),
            grouping.current?.checked ? ['route'] : undefined,
        );

        if (firstRun || lastSortKey.current !== response?.LastEvaluatedKey) {
            lastSortKey.current = response?.LastEvaluatedKey;
            if (response?.items?.length > 0) {
                //find orders not already in orders list

                const newOrders: (Order | null)[] = response.items.filter(
                    (o1) => orders.current.find((o) => o?.entityId === o1.entityId) === undefined,
                );

                //add new orders that are already 'processing' to the refresh tracker
                newOrders
                    .filter((o) => o !== null && isTemporaryState(o.state))
                    .forEach((o) => o !== null && orderRefreshTrackerRef.current!.addOrder(o));

                if (!response.LastEvaluatedKey) newOrders.push(null);

                orders.current = [...orders.current, ...newOrders];
            } else if (lastSortKey.current === response?.LastEvaluatedKey) {
                //do nothing, ignore this duplicate response
            } else {
                orders.current = [...orders.current, null];
            }
        }

        setRefreshTrigger((prev) => prev + 1);

        setIsNextPageLoading(false);
    };

    const isTemporaryState = (state: Order['state']) => {
        return (
            state === 'processing' ||
            state === 'pending-invalidated' ||
            state === 'pending-mapping' ||
            state === 'invalidated'
        );
    };

    //fetch missing mappings for pending orders
    const fetchMissingMappingsForPendingOrders = async (newOrders: (Order | null)[]) => {
        const pendingOrders = newOrders.filter((o) => o !== null && o.state === 'pending-mapping') as Order[];
        for (const pendingOrder of pendingOrders) {
            const blockingMappings = await apiClient.blockingMapping(
                pendingOrder.entityId,
                lang.code === 'fr' ? 'FR' : 'EN',
            );
            dispatch({
                type: ActionTypes.ADD_MISSING_MAPPING,
                payload: {
                    mappings: blockingMappings,
                },
            });
        }
    };

    //show popup with the list of blocking orders
    const [blockingMapping, setBlockingMapping] = useState<Mapping[]>();

    const formatter = new Intl.NumberFormat(lang.code === 'fr' ? 'fr-CA' : 'en-CA', {
        style: 'currency',
        currency: 'CAD',
    });

    const getDocNumberLabel = (order: Order, maxLength?: number) => {
        if (maxLength && order?.entityNumber?.length > maxLength) {
            const shortenedDocNum =
                order.entityNumber.substring(0, 5) +
                '...' +
                order.entityNumber.substring(order.entityNumber.length - 5, order.entityNumber.length);
            return shortenedDocNum;
        }
        return order?.entityNumber ?? t('N/A');
    };
    const getCustomerLabel = (order: Order) => {
        return order?.clientName ?? t('N/A');
    };

    const getAmountLabel = (order: Order) => {
        if (!order.amount) return t('N/A');
        return formatter.format(order.amount);
    };

    const getStatusLabel = (order: Order) => {
        if (order.state === 'invalidated') return <span>{t('Invalidated')}</span>;
        if (order.state === 'mapped') return <span>{t('Transferred')}</span>;
        if (order.state === 'processing') return <span>{t('Processing')}</span>;
        if (order.state === 'canceled') return <span>{t('Canceled')}</span>;
        if (order.state === 'error') return <span>{t('Error')}</span>;
        if (order.state === 'ignored') return <span>{t('Ignored')}</span>;
        if (order.state === 'pending-invalidated') return <span>{t('Invalidated')}</span>;
        if (order.state === 'pending-mapping')
            return (
                <Button
                    onClick={async () => {
                        const blockingMappings = await apiClient.blockingMapping(
                            order.entityId,
                            lang.code === 'fr' ? 'FR' : 'EN',
                        );
                        const mappings = blockingMappings?.filter((m) => m?.state === 'pending-mapping');
                        if ((mappings?.length ?? 0) > 0) setBlockingMapping(mappings);
                    }}
                >
                    <WarningIcon color='warning' />
                </Button>
            );
        if (order.state === 'pending-confirmation')
            return (
                <div>
                    <Button
                        variant='contained'
                        size='small'
                        onClick={() => {
                            confirmDialog(
                                t('Transfert order?'),
                                t('Are you sure you want to transfer this order?'),
                                t('Yes'),
                                t('No'),
                                'warning',
                                () => {
                                    if (order.entityId && orderRefreshTrackerRef.current) {
                                        apiClient.transmitOrder([order.entityId]);
                                        updateOrderStatus(order, 'processing');
                                        orderRefreshTrackerRef.current.addOrder(order);
                                    }
                                },
                            );
                        }}
                        style={{ marginRight: '10px' }}
                    >
                        {t('Transfert')}
                    </Button>
                </div>
            );
    };

    const getLastOperationLabel = (order: Order): string[] => {
        if (!order.lastUpdateDate) return [t('N/A')];

        const date = new Date(order.lastUpdateDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const formattedDate = [`${year}-${month}-${day}`, `${hours}:${minutes}:${seconds}`];
        return formattedDate;
    };

    function titleCase(string: string) {
        var sentence = string.toLowerCase().split(' ');
        for (var i = 0; i < sentence.length; i++) {
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
        }
        return sentence;
    }

    function updateOrderStatus(order: Order, status: Order['state']) {
        const index = orders.current.findIndex((o) => o !== null && o.entityId === order.entityId);
        if (index >= 0) {
            orders.current[index] = {
                ...orders.current[index],
                state: status,
            } as Order;
        }

        setRefreshTrigger((prev) => prev + 1);
    }

    const Header = ({}: {}) => {
        return (
            <Paper
                elevation={2}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '10px',
                }}
            >
                <Checkbox
                    style={{ flex: 1 }}
                    checked={
                        orders.current?.length > 0 &&
                        Object.keys(rowSelection).length === orders.current?.length &&
                        !hasNextPage
                    }
                    indeterminate={
                        Object.keys(rowSelection).length > 0 &&
                        (hasNextPage || Object.keys(rowSelection).length < (orders.current?.length ?? 0))
                    }
                    onChange={(event) => {
                        setRowSelection({} as typeof rowSelection);
                    }}
                />
                {(type.extraColumns ?? []).map((c) => (
                    <div key={`head-${c.id}`} style={{ flex: 4 }}>
                        {t(c.label)}
                    </div>
                ))}
                <div key={'head-docno'} style={{ flex: 3 }}>
                    {t('Doc No.')}
                </div>
                <div key={'head-customer'} style={{ flex: 6 }}>
                    {t('Customer')}
                </div>
                <div key={'head-amount'} style={{ flex: 3 }}>
                    {t('Amount')}
                </div>
                <div key={'head-status'} style={{ flex: 3 }}>
                    {t('Status')}
                </div>
                <div key={'head-lastop'} style={{ flex: 4 }}>
                    {t('Last operation')}
                </div>
                <div style={{ flex: 2.5 }}></div>
            </Paper>
        );
    };

    const rowRenderer = ({ index, style }: { index: number; style: CSSProperties }) => {
        if (orders.current.length <= index) return <>problems...</>;
        const order = orders.current[index];
        if (order === null) return <></>;
        return (
            <div
                key={order!.entityId}
                style={{
                    ...style,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    borderBottom: '1px solid #D3D3D3',
                    gap: '10px',
                }}
            >
                <Checkbox
                    key={'group-checkbox'}
                    style={{ flex: 1 }}
                    checked={!!rowSelection[order!.entityId]}
                    onChange={(event) => {
                        const newSelection = { ...rowSelection };

                        if (event.target.checked) {
                            if (grouping.current?.checked) {
                                const route = (order as any)['routeIdentifier'];
                                const items = orders.current.filter(
                                    (m) => m !== null && (m as any)['routeIdentifier'] === route,
                                );
                                items.filter((o) => o !== null).forEach((i) => (newSelection[i!.entityId] = i!));
                            } else newSelection[order!.entityId] = order!;
                        } else delete newSelection[order!.entityId];

                        setRowSelection(newSelection);
                    }}
                />
                {(type.extraColumns ?? []).map((c) => (
                    <Typography key={'custom' + c.id} style={{ flex: 4 }}>
                        {((order as any)[c.id]?.split('<br>') ?? []).map((s: string, index: number) => (
                            <span key={c.id + '-' + index}>
                                {index > 0 && <br />}
                                {s}
                            </span>
                        ))}
                    </Typography>
                ))}

                <Tooltip title={getDocNumberLabel(order!)} key={'docnum-tooltip'}>
                    <Typography key={'docnum-label'} style={{ flex: 3 }}>
                        {getDocNumberLabel(order!, 12)}
                    </Typography>
                </Tooltip>
                <Typography key={'customer-label'} style={{ flex: 6 }}>
                    {getCustomerLabel(order!)}
                </Typography>
                <Typography key={'amount-label'} style={{ flex: 3 }}>
                    {getAmountLabel(order!)}
                </Typography>
                <div key={'status-label'} style={{ flex: 3 }}>
                    {getStatusLabel(order!)}
                </div>
                <Typography key={'last-operation-label'} style={{ flex: 4 }}>
                    {getLastOperationLabel(order!).map((s: string, index: number) => (
                        <span key={'last-operation-label-' + index}>
                            {s}
                            <br />
                        </span>
                    ))}
                </Typography>
                <div style={{ display: 'flex', flex: 1 }}>
                    <Tooltip title={t('Reset')} key={'buttons-reset'}>
                        <span>
                            <IconButton
                                disabled={order.state === 'mapped' || !orderRefreshTrackerRef.current}
                                onClick={(event) => {
                                    confirmDialog(
                                        t('Reset order?'),
                                        t('Are you sure you want to reset this order?'),
                                        t('Yes'),
                                        t('No'),
                                        'warning',
                                        async () => {
                                            apiClient.resetOrder(order.entityId);
                                            orderRefreshTrackerRef.current!.addOrder(order);
                                            updateOrderStatus(order, 'processing');
                                            setOpenSnackbarMessage('Order has been reset');
                                        },
                                    );
                                }}
                            >
                                <ReplayIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={t('Ignore/Hide order')}>
                        <span>
                            <IconButton
                                disabled={
                                    !(
                                        order.state === 'pending-confirmation' ||
                                        order.state === 'pending-mapping' ||
                                        order.state === 'pending-invalidated' ||
                                        !orderRefreshTrackerRef.current
                                    )
                                }
                                onClick={(event) => {
                                    confirmDialog(
                                        t('Ignore order?'),
                                        t('Are you sure you want to ignore this order?'),
                                        t('Yes'),
                                        t('No'),
                                        'warning',
                                        () => {
                                            apiClient.ignoreOrder([order.entityId]);
                                            orderRefreshTrackerRef.current!.addOrder(order);
                                            updateOrderStatus(order, 'processing');
                                            setOpenSnackbarMessage('Order has been ignored/hidden');
                                        },
                                    );
                                }}
                            >
                                <VisibilityOffIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={t('Copy workflowId to help with debug')}>
                        <IconButton
                            onClick={(event) => {
                                const workflowId = order.integrationIdAndType + '_' + order.entityId;
                                navigator.clipboard.writeText(workflowId);
                                setOpenSnackbarMessage('WorkflowId copied to clipboard');
                            }}
                        >
                            <ContentCopyIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
        );
    };

    if (!orders || !config) {
        return (
            <div
                style={{
                    height: '100vh',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    //infinite scroll related stuff
    const hasNextPage = orders.current[orders.current.length - 1] !== null;
    const itemCount = hasNextPage ? orders.current.length + 1 : orders.current.length;
    const loadMoreItems = isNextPageLoading ? () => {} : () => fetchMoreOrders(false);
    const isItemLoaded = (index: number) => !hasNextPage || index < orders.current.length;

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                }}
            >
                <Button
                    style={{ width: '400px' }}
                    variant='contained'
                    size='small'
                    disabled={Object.keys(rowSelection).length === 0}
                    onClick={() => {
                        confirmDialog(
                            t('Transfert orders?'),
                            t('Are you sure you want to transfer these orders?'),
                            t('Yes'),
                            t('No'),
                            'warning',
                            () => {
                                const entityIds = Object.keys(rowSelection).filter((k) => k !== 'deliveryRouteAndDate');

                                const selectedOrders = entityIds.map((o) =>
                                    orders.current?.find((i) => i !== null && i.entityId === o),
                                );

                                const transferrableOrders = selectedOrders.filter(
                                    (o) => !!o && o.state === 'pending-confirmation',
                                );

                                apiClient.transmitOrder(transferrableOrders.map((o) => o!.entityId));
                                for (const order of transferrableOrders) {
                                    if (!order || !orderRefreshTrackerRef.current) continue;
                                    updateOrderStatus(order, 'processing');
                                    orderRefreshTrackerRef.current.addOrder(order);
                                }

                                setRowSelection({});
                            },
                        );
                    }}
                >
                    {`${t('Transfer')} ${Object.keys(rowSelection).length} ${t('orders')}`}
                </Button>
                <Tooltip
                    title={t('Display only orders that require your attention.')}
                    key={'show-pending-only-checkbox'}
                    style={{ display: 'flex', width: '200px' }}
                >
                    <div>
                        <input type='checkbox' ref={showMissingsOnly} />
                        <div className='label'>{t('Pending')}</div>
                    </div>
                </Tooltip>

                {type.extraColumns?.find((c) => c.groupable) && (
                    <Tooltip
                        title={t(
                            'Only display orders with delivery routes and enable quick selection of those orders.',
                        )}
                        key={'buttons-group-by-route'}
                    >
                        <div key={'group-by-route-checkbox'} style={{ display: 'flex', width: '350px' }}>
                            <input ref={grouping} type='checkbox' />
                            <div className='label'>{t('Group by route')}</div>
                        </div>
                    </Tooltip>
                )}
                <TextField
                    fullWidth={true}
                    key={'search-field'}
                    style={{ marginLeft: '10px' }}
                    size='small'
                    value={filterByOrderNumber.current}
                    label={t('Search by order number')}
                    onChange={(event) => {
                        filterByOrderNumber.current = event.target.value;
                        init();
                    }}
                />
                <Tooltip
                    title={t(
                        'Manual mode requires that you select and transmit orders, while the automatic mode does it as soon as they are received.',
                    )}
                    key={'buttons-manual-mode'}
                >
                    <ToggleButtonGroup
                        color='primary'
                        value={mode}
                        exclusive
                        onChange={(e, newMode: string) => {
                            if (newMode && mode !== newMode) {
                                confirmDialog(
                                    t('Change mode?'),
                                    `${t(`Are you sure you want to change mode to {{newMode}}?`, {
                                        newMode: newMode,
                                    })}
                                                    ${t('Manuel mode')}: ${t(
                                        ' waits until you click on transfert to send orders to {{software}}.',
                                        { software: config.type },
                                    )}
                                                    ${t('Automatic mode')}: ${t(
                                        ' sends orders to {{software}} as soon as they are identified.',
                                        { software: config.type },
                                    )}`,
                                    t('Yes'),
                                    t('No'),
                                    'warning',
                                    () => {
                                        setMode(newMode === 'manual' ? 'automatic' : 'manual');
                                        apiClient.saveConfig({
                                            transmitMode: newMode === 'manual' ? 'automatic' : 'manual',
                                        });
                                        dispatch({
                                            type: ActionTypes.SAVE_CONFIG,
                                            payload: {
                                                transmitMode: newMode,
                                            },
                                        });
                                    },
                                );
                            }
                        }}
                    >
                        <ToggleButton key={'manual'} value='manual'>
                            {t('manual')}
                        </ToggleButton>
                        <ToggleButton key={'automatic'} value='automatic'>
                            {t('automatic')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Tooltip>
            </div>
            <br />
            <div key={'order-div'} style={{ position: 'relative', height: '100vh' }}>
                <div style={{ position: 'sticky', top: 0, zIndex: 100 }}>
                    <Header />
                </div>
                <div style={{ overflow: 'auto', height: 'calc(100vh - 50px)' }}>
                    {!orders && (
                        <div
                            style={{
                                height: '100vh',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CircularProgress />
                        </div>
                    )}
                    <AutoSizer>
                        {({ height, width }) => (
                            <InfiniteLoader
                                isItemLoaded={isItemLoaded}
                                itemCount={itemCount}
                                loadMoreItems={loadMoreItems}
                            >
                                {({ onItemsRendered, ref }) => (
                                    <List
                                        height={height ?? 0}
                                        itemCount={orders.current.length}
                                        itemSize={55}
                                        width={width ?? 0}
                                        onItemsRendered={onItemsRendered}
                                        ref={ref}
                                    >
                                        {rowRenderer}
                                    </List>
                                )}
                            </InfiniteLoader>
                        )}
                    </AutoSizer>
                </div>
            </div>
            <Dialog open={!!blockingMapping} onClose={() => setBlockingMapping(undefined)}>
                <DialogTitle>{t('Missing mappings')}</DialogTitle>
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('Type')}</TableCell>
                            <TableCell>{t('Identifier')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {blockingMapping &&
                            blockingMapping.map((mapping) => (
                                <TableRow
                                    key={mapping.entityId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component='th' scope='row'>
                                        {t(titleCase(mapping.integrationIdAndType?.split('_')?.[1]))}
                                    </TableCell>
                                    <TableCell>{mapping.label}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <DialogActions>
                    <Button onClick={() => setBlockingMapping(undefined)} autoFocus>
                        {t('Close')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(openSnackbarMessage)}
                autoHideDuration={2000}
                onClose={() => setOpenSnackbarMessage(undefined)}
                message={t(openSnackbarMessage ?? '')}
            />
        </div>
    );
}
