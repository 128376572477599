import {
    Avatar,
    Badge,
    Collapse,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import { t } from 'i18next';
import Icons from './Icons';
import { LanguageSwitcher } from './LanguageSwitcher';

import LanguageIcon from '@mui/icons-material/Language';
import LogoutIcon from '@mui/icons-material/Logout';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import progyLinkLogo from '../images/progylink.svg';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { IntegrationContextType, useIntegration } from '../contexts/IntegrationProvider';
import { Mapping, WebAppConfig, WebappEntryType } from '@progyconnect/webapp-types';
import { useSelector } from 'react-redux';
import { State } from '../redux/state';
import { IntegrationsCombo } from './IntegrationCombo';

const NavigationDrawer = ({
    config,
    selection,
    setSelection,
    setConnectionInfoVisible,
    setShowPolicies,
}: {
    config: WebAppConfig;
    selection: WebappEntryType | undefined;
    setSelection: (selection: WebappEntryType) => void;
    setConnectionInfoVisible: (visible: boolean) => void;
    setShowPolicies: (show: boolean) => void;
}) => {
    const drawerWidth = 240;

    const { logout, user } = useAuth0();

    const handleAvatarMenuClick = () => {
        setAvatarMenuOpen(!avatarMenuOpen);
    };
    const [avatarMenuOpen, setAvatarMenuOpen] = useState(false);
    const { integrations } = useIntegration() as IntegrationContextType;
    const mappings = useSelector<State, { [key: string]: Mapping[] } | undefined>((state) => state.mappings);

    return (
        <Drawer
            sx={{
                width: drawerWidth,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                },
            }}
            variant='permanent'
            anchor='left'
        >
            <List style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <div>
                    <ListItemIcon>
                        <div className='image-container' style={{ padding: '10px' }}>
                            <img src={progyLinkLogo} alt='Progy Link' width='220'></img>
                            <span style={{ fontSize: '12px' }}>
                                développé par{' '}
                                <a href='https://www.progymedia.com' target='_blank' rel='noreferrer'>
                                    progymedia.com
                                </a>
                            </span>
                        </div>
                    </ListItemIcon>

                    <Divider />

                    <ListItem key={'avatar'} onClick={handleAvatarMenuClick} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <Avatar alt={user?.name} src={user?.picture} />
                            </ListItemIcon>
                            <ListItemText primary={user?.nickname} />
                            {avatarMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>
                    </ListItem>

                    <Collapse in={avatarMenuOpen} timeout='auto' unmountOnExit>
                        <List component='div' disablePadding dense>
                            <ListItem>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <LanguageIcon />
                                    </ListItemIcon>
                                    <LanguageSwitcher />
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton
                                    onClick={() =>
                                        logout({
                                            returnTo: window.location.href,
                                        })
                                    }
                                >
                                    <ListItemIcon>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    {t('Disconnect')}
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton
                                    onClick={() => {
                                        if (config.type === 'Acomba') setConnectionInfoVisible(true);
                                    }}
                                >
                                    <ListItemIcon>
                                        <HelpCenterIcon />
                                    </ListItemIcon>

                                    {t('Connected to: {{type}} ...', { type: config.type })}
                                </ListItemButton>
                            </ListItem>
                            {integrations && integrations.length > 1 && (
                                <ListItem>
                                    <IntegrationsCombo />
                                </ListItem>
                            )}
                        </List>
                    </Collapse>

                    <Divider />

                    {config!.entryTypes.map((item) => {
                        function getItemCount(key: string | undefined) {
                            if (!key || !mappings?.[key.toLowerCase()]) return undefined;

                            return mappings[key.toLowerCase()].filter((m) => m.state !== 'mapped').length;
                        }

                        return (
                            <ListItem key={item.class} disablePadding>
                                <ListItemButton
                                    selected={selection === item}
                                    onClick={() => {
                                        setSelection(item);
                                    }}
                                >
                                    <ListItemIcon>
                                        <Icons icon={item.icon} />
                                    </ListItemIcon>
                                    <ListItemText primary={t(item.label)} />
                                    <Badge badgeContent={getItemCount(item.class)} color='info' />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </div>

                <div style={{ alignSelf: 'flex-end' }}>
                    <ListItem key='policies' disablePadding>
                        <ListItemButton
                            onClick={() => {
                                setShowPolicies(true);
                            }}
                        >
                            <ListItemIcon>
                                <Icons icon={'Gavel'} />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <Typography sx={{ fontSize: '10px' }}>
                                        {t('Privacy Policy and Terms of Use')}
                                    </Typography>
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                </div>
            </List>
        </Drawer>
    );
};

export default NavigationDrawer;
function useEffect(arg0: () => void, arg1: never[]) {
    throw new Error('Function not implemented.');
}
