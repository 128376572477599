import { Dialog, DialogTitle, DialogActions, DialogContent, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';
import { useLocalization } from '../contexts/LocalizationContext';

function Changelog() {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const { lang } = useLocalization();
    const [lastChangelogDate, setLastChangelogDate] = useLocalStorage<string>('progyconnect-lastChangelog', '');

    useEffect(() => {
        if (!lastChangelogDate || logs?.[0].date !== lastChangelogDate) setOpen(true);
    }, [logs, lastChangelogDate]);

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='lg'>
            <DialogTitle>
                <h4>{t('Updates, fixes and improvements to ProgyLink.')}</h4>
            </DialogTitle>
            <DialogContent>
                <div className='changelog-list'>
                    {logs
                        .filter((l) => l.lang === lang.code)
                        .map((changelog, index) => (
                            <div key={index} className='changelog-item'>
                                <h3>{changelog.date}</h3>
                                <div>
                                    {changelog.newFeatures.length > 0 && (
                                        <>
                                            <h4>{t('New Features')}:</h4>
                                            <ul>
                                                {changelog.newFeatures.map((feature, index) => (
                                                    <li key={index}>{feature}</li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                </div>
                                <div>
                                    {changelog.improvements.length > 0 && (
                                        <>
                                            <h4>{t('Improvements')}:</h4>
                                            <ul>
                                                {changelog.improvements.map((improvement, index) => (
                                                    <li key={index}>{improvement}</li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                </div>
                                <div>
                                    {changelog.fixes.length > 0 && (
                                        <>
                                            <h4>{t('Fixes')}:</h4>
                                            <ul>
                                                {changelog.fixes.map((fix, index) => (
                                                    <li key={index}>{fix}</li>
                                                ))}
                                            </ul>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => {
                        setLastChangelogDate(logs[0].date);
                        setOpen(false);
                    }}
                >
                    {t("Don't show again")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

type logType = {
    lang: string;
    date: string;
    newFeatures: string[];
    improvements: string[];
    fixes: string[];
};

const logs: logType[] = [
    {
        lang: 'en',
        date: 'May 23th, 2023',
        newFeatures: [],
        improvements: [],
        fixes: ['Order status now updates correctly when transferred or ignored'],
    },
    {
        lang: 'fr',
        date: '23 mai 2023',
        newFeatures: [],
        improvements: [],
        fixes: ["Le statut de la commande se met maintenant à jour correctement lorsqu'elle est transférée ou ignorée"],
    },
    {
        lang: 'en',
        date: 'May 18th, 2023',
        newFeatures: [],
        improvements: [
            'Quicker response time when loading mapping values',
            'Privacy policy and terms of use are now available in the footer',
        ],
        fixes: [
            'New orders wont be fetched automatically and continuously, it was causing problems. You will need to refresh the page to get them.',
        ],
    },
    {
        lang: 'fr',
        date: '18 mai 2023',
        newFeatures: [],
        improvements: [
            'Temps de réponse plus rapide lors du chargement des valeurs de mappage',
            "La politique de confidentialité et les conditions d'utilisation sont maintenant disponibles dans le pied de page",
        ],
        fixes: [
            'Les nouvelles commandes ne seront pas récupérées automatiquement et en continu, cela posait des problèmes. Vous devrez rafraîchir la page pour les obtenir.',
        ],
    },
    {
        lang: 'en',
        date: 'May 2nd, 2023',
        newFeatures: ['Added support for Acomba'],
        improvements: ['Pagination in the orders list was replaced with an infinite scroll'],
        fixes: ['Many small fixes to application workflow'],
    },
    {
        lang: 'fr',
        date: '2 mai 2023',
        newFeatures: ['Ajout du support pour Acomba'],
        improvements: ['La pagination dans la liste des commandes a été remplacée par un défilement infini'],
        fixes: ["Plusieurs petites corrections au flux de travail de l'application"],
    },
    {
        lang: 'en',
        date: 'April 20, 2023',
        newFeatures: [],
        improvements: [
            'The email used in QBO when creating invoices is now the one obtained from the QBO customer file at the time of the transfer.',
        ],
        fixes: [],
    },
    {
        lang: 'fr',
        date: '20 avril 2023',
        newFeatures: [],
        improvements: [
            'Le courriel utilisé dans QBO lors de la création des factures est maintenant celui obtenu du dossier client QBO au moment du transfert.',
        ],
        fixes: [],
    },
    {
        lang: 'en',
        date: 'February 07, 2023',
        newFeatures: [],
        improvements: [
            'QBO invoice fields "Ship via", "Shipping date" and "Tracking No." are now filled automatically.',
        ],
        fixes: ['Better error handling during order processing.'],
    },
    {
        lang: 'fr',
        date: '07 février 2023',
        newFeatures: [],
        improvements: [
            'Champs de facture QBO "Expédier via", "Date d\'expédition" et "Numéro de suivi." sont maintenant remplis automatiquement.',
        ],
        fixes: ['Meilleure gestion des erreurs lors du traitement des commandes'],
    },
    {
        lang: 'en',
        date: 'January 25, 2023',
        newFeatures: [],
        improvements: [
            'Major overhaul of the orders and mappings interfaces. Operation is very similar, but simplified, and reported intermittent issues have been fixed.',
            'Products of type "Category" and Taxes of type "Adjustment" have been removed from the list of possibilities, because they are not supported on invoices and cause problems.',
        ],
        fixes: ['Products associated with sub-companies are now supported'],
    },
    {
        lang: 'fr',
        date: '25 janvier 2023',
        newFeatures: [],
        improvements: [
            'Révision majeure des interfaces de commandes et des correspondances. Le fonctionnement est très similaire, mais simplifié et les problèmes intermittents rapportés ont été corrigés.',
            'Certains produits de type "Catégorie" ont été retirés de la liste des possibilités, car ils ne sont pas supportés sur des factures et posent problème.',
            'Certaines taxes de type "Ajustement" ont été retirées de la liste des possibilités, car elles ne sont pas supportées sur des factures et posent problème.',
        ],
        fixes: ['Les produits associés à des sous-compagnies sont maintenant supportés'],
    },
];

export default Changelog;
