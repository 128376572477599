import SportsBarIcon from '@mui/icons-material/SportsBar';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DiscountIcon from '@mui/icons-material/Discount';
import LiquorIcon from '@mui/icons-material/Liquor';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DescriptionIcon from '@mui/icons-material/Description';
import CalculateIcon from '@mui/icons-material/Calculate';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import GavelIcon from '@mui/icons-material/Gavel';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';

import { IconType } from '@progyconnect/webapp-types';

const Icons = ({ icon }: { icon: IconType }) => {
    switch (icon) {
        case 'SportsBar':
            return <SportsBarIcon />;
        case 'EmojiPeople':
            return <EmojiPeopleIcon />;
        case 'AttachMoney':
            return <AttachMoneyIcon />;
        case 'Discount':
            return <DiscountIcon />;
        case 'Liquor':
            return <LiquorIcon />;
        case 'AccessTime':
            return <AccessTimeIcon />;
        case 'Description':
            return <DescriptionIcon />;
        case 'Calculate':
            return <CalculateIcon />;
        case 'Receipt':
            return <ReceiptIcon />;
        case 'LocalPizza':
            return <LocalPizzaIcon />;
        case 'Gavel':
            return <GavelIcon />;
        case 'HelpCenter':
            return <HelpCenterIcon />;

        default:
            return <></>;
    }
};

export default Icons;
